<template>
  <div class="footer">
    <footer class="align-items-ceneter">
      <div class="information d-flex pt-4">
        <div class="privacy d-flex">
          <p
            class="mtavruli"
            style="
              font-size: 12px;
              text-transform: uppercase;
              border: 2px solid #7e5493;
            "
          >
            &copy; 2022 SMARTPHARM - All RIGHTS RESERVD
          </p>
          <div v-for="item in privacy" :key="item.id" class="">
            <router-link
              :to="item.path"
              class="links mtavruli"
              style="
                font-size: 12px;
                text-transform: uppercase;
                margin-left: 30px;
              "
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
        <div class="social_networks ml-auto d-flex">
          <div v-for="item in items.follow" :key="item._id">
            <a :href="item.url" target="_blank">
              <img
                style="filter: brightness(100000%)"
                :src="`${server_url}/${item.image}`"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="copyright d-flex">
        <p>
          As a service to our readers, Harvard Health Publishing provides access
          to our library of archived content. Please note the date of last
          review or update on all articles. No content on this site, regardless
          of date, should ever be used as a substitute for direct medical advice
          from your doctor or other qualified clinician.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
export default {
  name: "foosdfsfter",
  data() {
    return {
      items: [],
      server_url: env.server_url,
      privacy: [
        { name: "Legal Statement", path: "/legalstatement" },
        { name: "Privacy Policy", path: "/privacy" },
        { name: "Contact Us", path: "/contact" },
      ],
      social_networks: [
        {
          url: require("../../../assets/icons/facebook.png"),
          link: "https://www.facebook.com/smartpharm",
        },
        {
          url: require("../../../assets/icons/linkedin.png"),
          link: "https://www.linkedin.com/company/smartpharmge",
        },
        {
          url: require("../../../assets/icons/instagram.png"),
          link: "https://www.instagram.com/smartpharmcompany/",
        },
        {
          url: require("../../../assets/icons/youtube.png"),
          link: " https://www.youtube.com/channel/UCwqxRKpWMfJyeIuUIg27p0Q/about",
        },
      ],
    };
  },
  mounted() {
    axios
      .get(`${env.host}/get/informations/contactInformation`)

      .then((result) => {
        this.items = result.data.item;
        this.email = result.data.item.email;
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
};
</script>

<style scoped>
.footer {
  background: #7f5496;
}
footer {
  max-width: 1400px;
  margin: auto;
  color: #e6e6e6;
}
.information {
  padding-left: 20px;
  padding-right: 20px;
}
.links {
  text-decoration: none;
  color: #f2f5fa !important;
}

.links {
  display: inline-block;
  position: relative;
}

.links::after {
  content: "";
  width: 0;
  height: 0.5px;
  position: absolute;
  transition: all 0.1s linear;
  background: #e8b1ff;
  left: 0;
  bottom: 0;
  transition-delay: 0.1s;
}
.links:hover::after {
  width: 100%;
  transition-delay: 0s;
}
.social_networks img {
  width: 25px;
  margin-right: 10px;
}
p {
  margin-bottom: 0px !important;
}

.copyright {
  width: 60%;
  padding: 0px 20px 15px 20px;
}
.copyright p {
  font-size: 13px;
}

::v-deep ul {
  border-radius: 0px !important;
}
@media all and (max-width: 991.5px) {
  .information,
  .copyright {
    display: block !important;
    margin: auto;
    width: 100%;
  }
  .information > div {
    margin-top: 10px;
  }
  .social_networks {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 697px) {
  .privacy {
    display: block !important;
  }
}

@media all and (max-width: 697px) {
  .links {
    margin-left: 0px !important;
  }
}
</style>
