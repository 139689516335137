<template>
  <div>
    <NavbarHeader />
    <nav>
      <b-navbar style="text-transform: uppercase" toggleable="lg" type="dark">
        <b-navbar-brand>
          <router-link to="/" exact
            ><img
              alt="smartpharm"
              class="logo"
              src="../../../assets/img/logoWhite.png"
          /></router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
          <img src="../../../assets/icons/burger.png" alt="" />
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <div @mouseover="onOver" @mouseleave="onLeave">
              <b-nav-item-dropdown
                :text="$t('navBar.about_us')"
                right
                ref="dropdown"
              >
                <b-dropdown-item v-for="item in aboutUs" :key="item.id">
                  <router-link :to="item.path" exact>
                    {{ item.title }}
                  </router-link>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
            <b-nav-item
              class="navbarHeaderItems d-none"
              v-for="item in navbarHeaderItems"
              :key="item.id"
            >
              <router-link :to="item.path" exact>
                {{ item.title }}
              </router-link>
            </b-nav-item>
            <b-nav-item v-for="item in navbarItems" :key="item.id">
              <router-link :to="item.path" exact>
                {{ item.title }}
              </router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </nav>
  </div>
</template>

<script>
import NavbarHeader from "../../../components/Navbar/newNavbar/NavbarHeader.vue";

export default {
  name: "navbar",
  data() {
    return {
      aboutUs: [
        { title: this.$t("navBar.company"), path: "/company" },
        { title: this.$t("navBar.values"), path: "/values" },
        { title: this.$t("navBar.history"), path: "/history" },
        { title: this.$t("navBar.newTeamPage"), path: "/newTeamPage" },
      ],
      navbarItems: [
        { title: this.$t("navBar.partners"), path: "/partners" },
        { title: this.$t("navBar.exclusive"), path: "/exclusiveMedicaments" },
        { title: this.$t("navBar.distributions"), path: "/distributions" },
        { title: this.$t("navBar.blog"), path: "/blogPage" },
        { title: this.$t("navBar.contact"), path: "/contact" },
      ],
      navbarHeaderItems: [
        { path: "/csr", title: this.$t("navBar.csr") },
        { path: "/vacancy", title: this.$t("navBar.vacancy") },
      ],
    };
  },
  components: {
    NavbarHeader,
  },
  methods: {
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
  },
};
</script>

<style scoped>
* {
  font-family: mtavruli;
  font-size: 14px;
}

nav {
  background-color: #7f5496;
}
.navbar-dark .navbar-toggler {
  border: none !important;
}
.navbar-dark .navbar-toggler img {
  width: 35px;
  height: 23px;
}
.navbar {
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 1400px;
  margin: auto;
  width: 100%;
}
.navbar-collapse {
  text-align: center;
}
::v-deep .dropdown-item a {
  text-decoration: none !important;
}
::v-deep .dropdown-item {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 8px !important;
}
::v-deep .dropdown-menu {
  text-align: center;
}
::v-deep .dropdown-item a,
.navbar .nav-link a {
  display: inline-block;
  position: relative;
}

::v-deep .dropdown-item a::after,
.navbar .nav-link a::after {
  content: "";
  width: 0;
  height: 0.5px;
  position: absolute;
  transition: all 0.1s linear;
  background: #e8b1ff;
  left: 0;
  bottom: 0;
  transition-delay: 0.1s;
}
::v-deep .dropdown-item a:hover::after,
.navbar .nav-link a:hover::after {
  width: 100%;
  transition-delay: 0s;
}
::v-deep .navbar-nav .dropdown-menu {
  background-color: #7f5496;
}
::v-deep .navbar-nav .dropdown-menu a {
  float: left;
}
::v-deep .dropdown-item:hover {
  background-color: transparent !important;
}
::v-deep.navbar .nav-link a,
::v-deep .navbar-nav .dropdown-menu a {
  color: #f2f5fa;
}

::v-deep .navbar-dark .navbar-nav .nav-link span {
  font-family: mtavruli !important;
  color: #f2f5fa !important;
}
.navbar .nav-link a {
  text-decoration: none;
}
.navbar .nav-link a:hover,
::v-deep .navbar-nav .dropdown-menu a:hover {
  color: #e8b1ff;
}
.logo {
  width: 170px;
}
.navbarHeaderItems span {
  color: red;
}
::v-deep .dropdown-toggle::after {
  display: none !important;
}
@media all and (max-width: 1450px) {
  .navbar {
    padding: 15px 20px 15px 20px;
  }
}
@media all and (max-width: 991.5px) {
  .navbarHeaderItems {
    display: block !important;
  }
  ::v-deep .dropdown-item {
    justify-content: center !important;
    padding: 0px;
  }
  ::v-deep .navbar-nav .dropdown-menu {
    height: 100px;
    width: 30%;
    margin: auto;
  }
}
</style>
