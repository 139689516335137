<template>
  <div class="language">
    <a href="#">
      <div v-on:click="changeLocale('ka')">
        <span>GE</span>
      </div>
    </a>
    <a href="#">
      <div v-on:click="changeLocale('en')">
        <span>EN</span>
      </div>
    </a>
  </div>
</template>

<script>
import i18n from "../../i18n.js";
import { localize } from "vee-validate";
export default {
  name: "languageSwitcher",
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleShow: function () {
      this.showMenu = !this.showMenu;
    },
    itemClicked: function (item) {
      this.toggleShow();
      this.onClick(item);
    },
    changeLocale(n) {
      i18n.locale = n;
      localize(n);
      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute("lang", i18n.locale);
      localStorage.setItem("lang", n);
      location.reload();
    },
  },
};
</script>

<style scoped>
a {
  text-decoration-color: #a705ff;
}
.language a::after {
  content: "";
  width: 0;
  height: 0.5px;
  position: absolute;
  transition: all 0.1s linear;
  background: #a705ff !important;
  color: #a705ff;
  left: 0;
  bottom: 0;
  transition-delay: 0.1s;
}
.language a:hover::after {
  width: 100%;
  transition-delay: 0s;
}
.language {
  display: flex;
}

span {
  padding-right: 20px;
  color: #666666;
  font-size: 13px;
  font-family: mtavruli;
}
</style>
