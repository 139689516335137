<template>
  <div class="navbar_header d-flex">
    <LanguageSwitcher />
    <div class="ml-auto d-flex p-1" style="text-transform: uppercase">
      <div class="input">
        <form @submit.prevent="myFunction" class="p-0">
          <input
            class="p-0 pl-1"
            v-model="searchKey"
            :placeholder="$t('front.search')"
          />
        </form>
      </div>
      <div
        v-for="item in navbarHeaderItems"
        :key="item.id"
        class="navbar_items ml-3"
      >
        <router-link :to="item.path" exact>
          {{ item.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher.vue";
export default {
  name: "navbarHeader",
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      searchKey: "",
      navbarHeaderItems: [
        { path: "/csr", title: this.$t("navBar.csr") },
        { path: "/vacancy", title: this.$t("navBar.vacancy") },
      ],
    };
  },
  methods: {
    myFunction() {
      // e.preventDefault();
      // alert("The form was submitted");
      // console.log("submited");
      window.location.href = `/searchPage/${this.searchKey}`;
    },
  },
};
</script>

<style scoped>
* {
  font-family: mtavruli;
  font-size: 13px;
}

.navbar_header {
  height: 50px;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  width: 100%;
}
.navbar_items a {
  color: #666666;
}
.navbar_items a {
  text-decoration: none;
}
.navbar_items a {
  display: inline-block;
  position: relative;
}
.navbar_items a::after {
  content: "";
  width: 0;
  height: 0.5px;
  position: absolute;
  transition: all 0.1s linear;
  background: #a705ff;
  left: 0;
  bottom: 0;
  transition-delay: 0.1s;
}
.navbar_items a:hover::after {
  width: 100%;
  transition-delay: 0s;
}
form input {
  outline: none;
  border: 1px solid #462359;
  padding: 5px;
  background: transparent;
  color: #462359;
}
input {
  padding-left: 5px;
  border-radius: 1px;
  outline: none;
  font-family: dejavu !important;
  border: 1px solid #7f5496;
}
@media all and (max-width: 1450px) {
  .navbar_header {
    padding: 0 20px 0 20px;
  }
}
@media all and (max-width: 991.5px) {
  .navbar_items {
    display: none;
  }
}
</style>
